<template>
  <div>
    <div class="app-header">
      <Header :isTransparent="true" />
    </div>
    <div class="app-container app-home">
      <div class="banner">
        <img class="bg-image" :src="defaultImage" alt="" />
        <h1 style="display: none">{{ $t("home.tip") }}</h1>
        <img class="bg-image__title1" :src="pg1" alt="" />
        <h2 class="bg-image__title2">
          {{ $t("home.h2") }}
        </h2>
        <h3 style="display: none">{{ $t("free_try") }}</h3>
        <img
          class="bg-image__title3"
          @click="setDialogVisible({ login: true })"
          :src="pg3"
          alt=""
        />
        <video
          class="background-video"
          autoplay
          loop=""
          muted=""
          data-video="0"
        >
          <source
            src="https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/video/banner.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div class="process">
        <div class="process-bg"></div>
      </div>
      <div class="owners">
        <div class="title-ele">
          <div class="box">
            <div class="ball">
              <img class="inner" :src="findpg" alt="" />
            </div>
          </div>
        </div>

        <div class="title">{{ $t("home.tip2") }}</div>
        <div class="list">
          <div class="row">
            <div
              class="block-wrapper"
              v-for="wrapper in changeListWrapper1"
              @animationend="animationend('changeListWrapper1')"
              :key="wrapper[0].id"
            >
              <div class="block" v-for="change in wrapper" :key="change.id">
                <div class="block__logo"><img :src="change.avatarUrl" /></div>
                <div class="block__content">
                  <div class="block__content_t text-grey text-md">
                    {{ change.userName }}
                  </div>
                  <div class="block__content_b text-grey text-md">
                    <span v-relative-time="change.dateline"></span>
                    {{ $t("change") }}
                    <span class="block__content_b_price text-orange"
                      >{{ change.amount }}{{ $t("yuan") }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row row-middle">
            <div
              class="block-wrapper"
              v-for="wrapper in changeListWrapper2"
              @animationend="animationend('changeListWrapper2')"
              :key="wrapper[0].id"
            >
              <div class="block" v-for="change in wrapper" :key="change.id">
                <div class="block__logo"><img :src="change.avatarUrl" /></div>
                <div class="block__content">
                  <div class="block__content_t text-grey text-md">
                    {{ change.userName }}
                  </div>
                  <div class="block__content_b text-grey text-md">
                    <span v-relative-time="change.dateline"></span>
                    {{ change.surveyTitle }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="block-wrapper"
              v-for="wrapper in changeListWrapper3"
              @animationend="animationend('changeListWrapper3')"
              :key="wrapper[0].id"
            >
              <div class="block" v-for="change in wrapper" :key="change.id">
                <div class="block__logo"><img :src="change.avatarUrl" /></div>
                <div class="block__content">
                  <div class="block__content_t text-grey text-md">
                    {{ change.userName }}
                  </div>
                  <div class="block__content_b text-grey text-md">
                    <span v-relative-time="change.dateline"></span>
                    {{ $t("home.tip3") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-footer">
      <Footer />
    </div>
  </div>
</template>
<script>
import * as defaultImage from "../../assets/images/video-default.jpg";
import * as findpg from "../../assets/images/home-find.png";
import * as pg1 from "../../assets/images/home-pg1.png";
import * as pg2 from "../../assets/images/home-pg2.png";
import * as pg3 from "../../assets/images/home-pg3.png";
import Header from "components/header";
import Footer from "components/footer";
import { mapActions } from "vuex";

export default {
  name: "home",
  data() {
    return {
      changes: [],
      defaultImage,
      findpg,
      pg1,
      pg2,
      pg3,
      changeListWrapper1: [],
      changeListWrapper2: [],
      changeListWrapper3: []
    };
  },
  created() {
    this.$http.get("/api/v1/Changes").then(res => {
      if (Array.isArray(res.data) && res.data.length > 0) {
        this.changes = res.data;
        this.changeListWrapper1 = [
          this.changes.slice(0, 5),
          this.changes.slice(5, 10)
        ];
        this.changeListWrapper3 = [
          this.changes.slice(10, 15),
          this.changes.slice(15, 20)
        ];
      }
    });
    this.$http.get("/api/v1/Completes").then(res => {
      if (Array.isArray(res.data) && res.data.length > 0) {
        this.changes = res.data;
        this.changeListWrapper2 = [
          this.changes.slice(0, 6),
          this.changes.slice(6, 11)
        ];
      }
    });
    this.$http.get("/api/v1/Panelists").then(res => {
      if (Array.isArray(res.data) && res.data.length > 0) {
        this.changes = res.data;
        this.changeListWrapper3 = [
          this.changes.slice(0, 5),
          this.changes.slice(5, 10)
        ];
      }
    });
  },
  methods: {
    ...mapActions(["setDialogVisible"]),
    animationend(key) {
      this[key] = [this[key][1], this[key][0]];
    }
  },
  components: {
    Header,
    Footer
  }
};
</script>
<style src="./index.scss" lang="scss" />
